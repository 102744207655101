import { fetchChallenges } from "@/api/challenges";


export default {
	state(){
		return {
			allChallenges: []
		}
	},
	mutations: {
		setChallenges(state, payload){
			state.allChallenges = payload.results
		}
	},
	getters: {
		getChallenges(state){
			return state.allChallenges
		},
		getChallengesByPointId: (state) => (id) => {
			return state.allChallenges.filter(challenge => challenge.point == id)
		}
	},
	actions: {
		async getAllChallenges({commit}){
			const response = await fetchChallenges()
			commit('setChallenges', {
				results: response.results
			})
		}
	},
}