import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Cookies from 'js-cookie'
import i18n from './lang'

import enLang from 'element-plus/lib/locale/lang/en'



import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log
import './utils/array-utils'
import SvgIcon from '@/components/SvgIcon'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import axios from "axios";
import VueEditor from '@/components/VueEditor'
import 'normalize.css/normalize.css' // a modern alternative to CSS resets

import './styles/element-variables.scss'
import '@/styles/index.scss' // global css

// svg component

const app = createApp(App)

//app.config.productionTip = false

axios.defaults.baseURL = 'http://csod.digied.si/api/v2'


for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}
// register globally
app.component('VueEditor', VueEditor)

app.component('SvgIcon', SvgIcon)
app.use(ElementPlus, {
  size: Cookies.get('size') || 'medium', // set elwement-ui default size
  i18n: (key, value) => i18n.t(key, value)
  //locale: enLang 
})
app.use(router)
app.use(store)
app.use(i18n)
app.mount('#app')

export default app