import Layout from '@/layout'

const centresRouter = {
    path: '/centres',
    component: Layout,
    //redirect: '/centres',
    //alwaysShow: false,
    //hidden: true,
    meta: { roles: ['admin'], permissions: ['admin'] },
    children: [
      {
        path: '',
        component: () => import('@/views/centres/CentresView.vue'),
        name: 'Centres',
        meta: {title: 'Centres', icon: 'centre', affix: true, requiresAuth: true, }
      },{
        path: ':id',
        component: () => import('@/views/centres/EditCentreView.vue'),
        name: 'CentresEdit',
        meta: {title: 'Centre '},
        hidden: true
      },
	  {
		path: 'add',
		component: () => import('@/views/centres/EditCentreView.vue'),
		name: 'AddCentre',
		meta: {title: 'Add centre'},
		hidden: true
	  }
    ]
  }
export default centresRouter