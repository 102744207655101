import Layout from '@/layout'

const missionsRouter = {
    path: '/mission',
    component: Layout,
    //redirect: '/mission/:id(\\d+)',
    meta: { title: 'Missions', noCache: true, affix: true, roles: ['admin', 'editor'], permissions: ['admin', 'editor'] },
    children: [
      {
        path: '',
        component: () => import('@/views/missions/MissionsView.vue'),
        name: 'Missions',
        meta: { title: 'Mission', icon: 'mission', affix: false },
        hidden: false
      },
      {
        path: ':id',
        component: () => import('@/views/missions/EditMission.vue'),
        name: 'MissionEdit',
        meta: { title: 'Missions' },
        hidden: true
      },
	  {
		path: 'add',
		component: () => import('@/views/missions/EditMission.vue'),
		name: 'MissionCreate',
		meta: { title: 'Add mission' },
		hidden: true
	  },
      {
        path: ':id/point/:pid',
        component: () => import('@/views/missions/points/EditPointsView.vue'),
        name: 'PointEdit',
        meta: {title: 'Point'},
        hidden: true
      },
      {
        path: ':id/point/add',
        component: () => import('@/views/missions/points/EditPointsView.vue'),
        name: 'PointAdd',
        meta: {title: 'Point'},
        hidden: true
      },
      {
        path: ':id/point/:pid/challenge/:cid',
        component: () => import('@/views/missions/points/challenge/edit'),
        name: 'ChallengeEdit',
        meta: { title: 'Challenge' },
        hidden: true
      },
      {
        path: ':id/point/:pid/challenge/add',
        component: () => import('@/views/missions/points/challenge/edit'),
        name: 'ChallengeAdd',
        meta: { title: 'Challenge' },
        hidden: true
      }
    ]
  }
export default missionsRouter