import { createI18n } from 'vue-i18n'
import Cookies from 'js-cookie'

import elementEnLocale from 'element-plus/lib/locale/lang/en' // element-ui lang
import elementSlLocale from 'element-plus/lib/locale/lang/sl'// element-ui lang
import elementNBLocale from 'element-plus/lib/locale/lang/nb-no'// element-ui lang

import enLocale from './en'
import slLocale from './si'
import nbLocale from './nb'

const messages = {
  en: {
    ...enLocale,
    ...elementEnLocale
  },

  sl: {
    ...slLocale,
    ...elementSlLocale
  },
  nb: {
    ...nbLocale,
    ...elementNBLocale
  },
}
export function getLanguage() {
  const chooseLanguage = Cookies.get('language')
  if (chooseLanguage) return chooseLanguage

  // if has not choose language
  const language = (navigator.language || navigator.browserLanguage).toLowerCase()
  const locales = Object.keys(messages)
  for (const locale of locales) {
    if (language.indexOf(locale) > -1) {
      return locale
    }
  }
  return 'en'
}
const i18n = new createI18n({
  // set locale
  // options: en | si
  allowComposition: true, // you need to specify that!
  locale: getLanguage(),
  // set locale messages
  messages
})

export default i18n