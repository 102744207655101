<!--template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>


import { onMounted, ref } from 'vue'
import {mapGetters, useStore} from 'vuex'

export default {
  name:"App",

}

onMounted(async () => {
  const store = useStore()
  console.log("onMounted store", store)
})
</script>

<--style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style-->



<template>
  <div id="app" v-if="def_data_loaded">
    <router-view />
  </div>
</template>

<script>



import {mapGetters, useStore} from 'vuex'

export default {
  name:"App",
  beforeCreate: function() {
      //const foo = this.defaults.cards
       // console.log("beforeCreate", foo);
  },
  data(){
    return {
      default:{}
    }
  },
  computed:{
    ...mapGetters({
            defaults: "getDefaultData"
        }),
      def_data_loaded(){
       // / console.log("def_data_loaded", this.default.cards)
        if (this.default && this.default.cards){
          return true
        }
        return true
    }
  },
  mounted() {
    const store = useStore()

    console.log("onMounted store", store)
  }
}
</script>

<!--style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style-->
