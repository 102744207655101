export default {
	state(){
		return {
			dialogState: {
				opened: false,
				onConfirmCallback: null,
			}
		}
	},
	mutations: {
		setDialogState(state, payload){
			state.dialogState = payload
		},
		hideDialog(state){
			state.dialogState = {
				opened: false,
				onConfirmCallback: null,
			}
		}
	},
	getters: {
		getDialogState(state){
			return state.dialogState
		},
		getOpenedState(state){
			return state.dialogState.opened
		},
		getOnConfirmCallback(state){
			return state.dialogState.onConfirmCallback
		}
	},
	actions: {
		openDialog({commit}, payload){
			commit('setDialogState', {
				opened: true,
				onConfirmCallback: payload,
			})
		},
		closeDialog({commit}){
			commit('hideDialog')
		}
	},
}