<template>
  <el-dialog 
    :modelValue="opened"
    align-center
    title="Are you sure?"
  >
    <p>Are you sure you want to delete this item</p>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="store.dispatch('closeDialog')">No</el-button>
        <el-button type="primary" @click="confirmButtonClicked">
          Yes
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

const store = useStore();

const opened = computed(() => store.getters.getOpenedState);
const onConfirmCallback = computed(() => store.getters.getOnConfirmCallback);

function confirmButtonClicked(){
  onConfirmCallback.value();
  store.dispatch('closeDialog');
}

</script>
<style scoped>
</style>