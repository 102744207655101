<template>
  <section class="app-main">
    <are-you-sure-dialog />
    <router-view v-slot="{ Component, route }">
      <transition name="fade-transform" mode="out-in">
        <div>
          <component :is="Component" :key="route.path" />
        </div>
      </transition>
    </router-view>
  </section>
</template>

<script setup>
import AreYouSureDialog from "@/components/AreYouSureDialog/AreYouSureDialog.vue";
import {computed, onMounted, ref} from "vue";
import { onBeforeRouteUpdate } from "vue-router";
import { useStore } from "vuex";

const store = useStore()

onMounted(async ()=>{
  await Promise.all([
    store.dispatch("fetchDefaultData")
	  //store.dispatch("getAllChallenges"),

  ])
})

onBeforeRouteUpdate(async (to, from) => {
  console.log(to)
  console.log(from)
  
})

const cachedViews = computed(() => store.state.tagsView.cachedViews)
</script>

<style lang="scss" scoped>
.app-main {
  min-height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  overflow: hidden;
}
.fixed-header + .app-main {
  padding-top: 50px;
}
.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 34 */
    min-height: calc(100vh - 84px);
  }

  .fixed-header + .app-main {
    padding-top: 84px;
  }
}
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>
