import axios from 'axios'
import {
    ElMessage,
    ElMessageBox
} from 'element-plus'
import store from '@/store'
import {getToken} from '@/utils/auth'

// create an axios instance

const service = axios.create({
    baseURL: (process.env.NODE_ENV !== 'production') ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API_PROD, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 5000 // request timeout
})
console.log(service.defaults.baseURL);
// request interceptor
service.interceptors.request.use(
    config => {
        // do something before request is sent
        /*
        if (config.method == "patch" || config.method == "post" || config.method == "put"){
          config.headers['Content-Type'] = "multipart/form-data"
        }

         */
        if (store.getters.token) {
            // let each request carry token
            // ['X-Token'] is a custom headers key
            // please modify it according to the actual situation
            config.headers['Authorization'] = "Token " + getToken()
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data

        // if the custom code is not 20000, it is judged as an error.s
        const is_logout_url = response.config.url.indexOf("logout") > -1 && response.status == 204
        console.log(response.status)
        console.log(response)
        if (response.status !== 200 && !is_logout_url && response.status !== 201 && response.status !== 204) {


            ElMessage({
                message: res.message || 'Error',
                type: 'error',
                duration: 5 * 1000
            })

            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
                // to re-login
                ElMessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
                    confirmButtonText: 'Re-Login',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    store.dispatch('user/resetToken').then(() => {
                        location.reload()
                    })
                })
            }
            console.log('err' + error) // for debug
            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            if (response.status == 204) {
                ElMessage({
                    message: "Deleted" || 'Error',
                    type: 'error',
                    duration: 5 * 1000
                })
            }
            return res
        }
    },
    error => {
        console.log('err' + error.request.responseText) // for debug
        let errors = JSON.parse(error.request.responseText)
        errors = error.request.responseText;
        let err_msg = ""
        if (Array.isArray(errors)) {
            errors.forEach(function (i, v) {
                let keys = Object.keys(errors)
                keys.forEach(function (v, i, k) {
                    err_msg += keys + ": " + v
                    err_msg += "\n"
                })
            })
        } else {
            let keys = Object.keys(errors)
            keys.forEach(function (v, i, k) {
                err_msg += keys + ": " + v

            })

        }

        ElMessage({
            message: errors.replace(/[\[\]\{\}]/g, ''), //error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service
