import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import Layout from "@/layout";
import cardsRouter from "@/router/modules/cards";
import missionsRouter from "@/router/modules/missions";
import centresRouter from "@/router/modules/centres";
import profileRouter from "@/router/modules/profile";

export const constantRoutes = [
    {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/LoginView.vue'),
    hidden: true
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    hidden: true
  },
  {
    path: '/info',
    component: () => import('@/views/info/index'),
    redirect: '/info',
  },
  {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        component: () => import('@/views/dashboard/index'),
        name: 'Dashboard',
        meta: { title: "Dashboard", icon: 'dashboard', affix: true }
      }
    ]
  },

]
export const asyncRoutes = [
  {},
    missionsRouter,
  centresRouter,
  cardsRouter,
  profileRouter
]
const createCustomRouter = () => createRouter({
  mode: 'history', // require service support
  history: createWebHistory(),
  routes: constantRoutes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },

})

const router = createCustomRouter()
// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createCustomRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router