export default {
  route: {
    dashboard: 'Namizje',
    documentation: 'Dokumentacija',
    guide: 'Vodič',
    permission: 'Dovoljenje',
    pagePermission: 'Dovoljenje za stran',
    rolePermission: 'Dovoljenje za vlogo',
    directivePermission: 'Direktivno dovoljenje',
    icons: 'Ikone',
    components: 'Komponente',
    tinymce: 'Tinymce',
    markdown: 'Markdown',
    jsonEditor: 'JSON Urejevalnik',
    dndList: 'Dnd Seznam',
    splitPane: 'SplitPane',
    avatarUpload: 'Nalaganje avatarja',
    dropzone: 'Dropzone',
    sticky: 'Lepljivo',
    countTo: 'Številčenje do',
    componentMixin: 'Mixin',
    backToTop: 'Nazaj na vrh',
    dragDialog: 'Vleci pogovor',
    dragSelect: 'Vleci izberi',
    dragKanban: 'Vleci Kanban',
    charts: 'Grafikoni',
    keyboardChart: 'Tipkovni grafikon',
    lineChart: 'Črtni grafikon',
    mixChart: 'Mešani grafikon',
    example: 'Primer',
    nested: 'Ugnezdeni Roti',
    menu1: 'Meni 1',
    'menu1-1': 'Meni 1-1',
    'menu1-2': 'Meni 1-2',
    'menu1-2-1': 'Meni 1-2-1',
    'menu1-2-2': 'Meni 1-2-2',
    'menu1-3': 'Meni 1-3',
    menu2: 'Meni 2',
    Table: 'Tabela',
    dynamicTable: 'Dinamična tabela',
    dragTable: 'Vleci tabelo',
    inlineEditTable: 'Inline urejanje',
    complexTable: 'Kompleksna tabela',
    tab: 'Zavihek',
    form: 'Obrazec',
    createArticle: 'Ustvari članek',
    editArticle: 'Uredi članek',
    articleList: 'Seznam člankov',
    errorPages: 'Strani z napakami',
    page401: '401',
    page404: '404',
    errorLog: 'Dnevnik napak',
    excel: 'Excel',
    exportExcel: 'Izvozi Excel',
    selectExcel: 'Izvozi izbrano',
    mergeHeader: 'Združi glavo',
    uploadExcel: 'Naloži Excel',
    zip: 'Zip',
    pdf: 'PDF',
    exportZip: 'Izvozi Zip',
    theme: 'Tema',
    clipboardDemo: 'Clipboard',
    i18n: 'I18n',
    externalLink: 'Zunanja povezava',
    profile: 'Profil'
  },
  login: {
    title: 'Prijavna forma',
    logIn: 'Prijava',
    username: 'Uporabniško ime',
    password: 'Geslo',
    any: 'katero koli',
    thirdparty: 'Ali se povežite z',
    thirdpartyTips: 'Ni mogoče simulirati lokalno, zato prosimo, da združite svojo poslovno simulacijo! ! !'
  },
  documentation: {
    documentation: 'Dokumentacija',
    github: 'Github Repozitorij'
  },
  permission: {
    addRole: 'Nova vloga',
    editPermission: 'Uredi',
    roles: 'Vaše vloge',
    switchRoles: 'Zamenjaj vloge',
    tips: 'V nekaterih primerih uporaba v-permission ne bo imela učinka. Na primer: Element-UI el-tab ali el-table-column in druge scene, ki dinamično upodabljajo dom. To lahko storite samo z v-if.',
    delete: 'Izbriši',
    confirm: 'Potrdi',
    cancel: 'Prekliči'
  },
  guide: {
    description: 'Stran z vodnikom je uporabna za nekatere ljudi, ki prvič vstopijo v projekt. Lahko na kratko predstavite funkcije projekta. Demo temelji na',
    button: 'Prikaži vodič'
  },
  components: {
    documentation: 'Dokumentacija',
    tinymceTips: 'Obogateno besedilo je osnovna funkcija upravljalnega zaledja, hkrati pa je to mesto s številnimi pastmi. V procesu izbire obogatenih besedil sem se prav tako veliko trudil. Večina običajnih obogatenih besedil na trgu je bila osnovno uporabljena, na koncu pa sem izbral Tinymce. Oglejte si podrobnejšo primerjavo in predstavitev obogatenega besedila.',
    dropzoneTips: 'Ker ima moje podjetje posebne potrebe in mora nalagati slike na qiniu, namesto tretje osebe sem se odločil, da ga sam vključim. Je zelo preprosto, podrobno kodo si lahko ogledate v @/components/Dropzone.',
    stickyTips: 'ko se stran pomakne na prednastavljeno mesto, bo ostala prilepljena na vrh.',
    backToTopTips1: 'Ko se stran pomakne na določeno mesto, se v spodnjem desnem kotu prikaže gumb Nazaj na vrh',
    backToTopTips2: 'Lahko prilagodite slog gumba, prikažete/skrijete, višino prikaza, višino vračanja. Če potrebujete besedilni poziv, lahko uporabite element-ui el-tooltip elemente zunaj',
    imageUploadTips: 'Ker sem uporabljal samo različico vue@1 in trenutno ni združljiva z mockjs, sem jo spremenil sam, in če jo boste uporabljali, je bolje uporabiti uradno različico.'
  },
  example: {
    warning: 'Strani za ustvarjanje in urejanje ni mogoče predpomniti z uporabo keep-alive, ker vključi keep-alive trenutno ne podpira predpomnjenja na podlagi poti, zato je trenutno predpomnjeno na podlagi imena komponente. Če želite doseči podoben učinek predpomnjenja, lahko uporabite shemo predpomnjenja brskalnika, kot je localStorage. Ali pa ne uporabljajte keep-alive in vključite neposredno predpomnjenje vseh strani. Glejte podrobnosti'
  },
  errorLog: {
    tips: 'Kliknite ikono hrošča v zgornjem desnem kotu',
    description: 'Zdaj je upravljalni sistem v bistvu v obliki spa, izboljšuje uporabniško izkušnjo, vendar tudi povečuje možnost težav na strani, majhna nepazljivost lahko privede do celotnega zaklepanja strani. Na srečo Vue ponuja način za zajemanje obravnavanja izjem, kjer lahko obravnavate napake ali prijavite izjeme.',
    documentation: 'Uvod v dokument'
  },
  excel: {
    export: 'Izvozi',
    selectedExport: 'Izvozi izbrane elemente',
    placeholder: 'Prosimo, vnesite ime datoteke (privzeto excel-list)'
  },
  zip: {
    export: 'Izvozi',
    placeholder: 'Prosimo, vnesite ime datoteke (privzeto file)'
  },
  pdf: {
    tips: 'Tukaj uporabljamo window.print() za izvedbo funkcije prenosa PDF.'
  },
  theme: {
    change: 'Spremeni temo',
    documentation: 'Dokumentacija teme',
    tips: 'Nasveti: To se razlikuje od izbire teme na vrstici z orodji, saj sta to dve različni metodi preoblačenja, vsaka s svojimi aplikacijskimi scenariji. Za podrobnosti glejte dokumentacijo.'
  },
  tagsView: {
    refresh: 'Osveži',
    close: 'Zapri',
    closeOthers: 'Zapri druge',
    closeAll: 'Zapri vse'
  },
  settings: {
    title: 'Nastavitev stila strani',
    theme: 'Barva teme',
    tagsView: 'Odpri Tags-View',
    fixedHeader: 'Fiksna glava',
    sidebarLogo: 'Logotip stranske vrstice'
  },
  navbar: {
    dashboard: 'Nadzorna plošča',
    github: 'Github',
    logOut: 'Odjava',
    profile: 'Profil',
    theme: 'Tema',
    size: 'Globalna velikost',
    save: 'Shrani',
    saveAndReturn: 'Shrani in nazaj'
  },
  mission: {
    mission: 'Misija:',
    basicInfo: 'Osnovne informacije',
    name: 'Ime misije',
    location: 'Lokacija',
    duration: 'Trajanje',
    distance: 'Dolžina (razdalja)',
    author: 'Avtor/ji',
    centre: 'Center',
    centrePlaceholder: 'Prosimo, izberite center',
    settings: 'Nastavitve',
    missionType: 'Tip misije',
    missionTypePlaceholder: 'Prosimo, izberite tip misije',
    language: 'Jezik',
    languagePlaceholder: 'Prosimo, izberite jezik',
    theme: 'Tema',
    themePlaceholder: 'Prosimo, izberite temo',
    area: 'Področje / Predmet',
    areaPlaceholder: 'Prosimo, izberite področje',
    level: 'Nivo',
    levelPlaceholder: 'Prosimo, izberite težavnost',
    editors: 'Uredniki',
    addEditor: 'Dodaj urednika',
    hiddenMission: 'Skrita misija',
    remoteMission: 'Oddaljena misija',
    lockedMission: 'Zaklenjena misija',
    missionCode: 'Koda misije',
    checkInRange: 'Območje prijave',
    distanceFromSight: 'Razdalja od znamenitosti',
    selectedNoOfPoint: 'Izbrano število točk',
    missionPresentation: 'Predstavitev misije',
    missionImages: 'Slike misije',
    titleImage: 'Naslovna slika',
    thumbnail: 'Sličica',
    badge: 'Značka',
    badgeImages: 'Slike značk',
    complete: 'Dokončano',
    points: 'Točke',
    addPoint: 'Dodaj točko',
    id: 'ID',
    title: 'Naslov',
    operation: 'Operacije',
    drag: 'Povleci',
    pointConnections: 'Povezave točk',
    confirm: 'Potrdi',
    cancel: 'Prekliči'
  },
  point: {
    point: 'Točka:',
    basicInfo: 'Osnovne informacije',
    title: 'Naslov',
    subtitle: 'Podnaslov',
    card: 'Kartica',
    cardPlaceholder: 'Prosimo, izberite kartico',
    settings: 'Nastavitve',
    latitude: 'Zemljepisna širina',
    longitude: 'Zemljepisna dolžina',
    thumbnail: 'Sličica',
    description: 'Opis',
    audio: 'Avdio',
    gallery: 'Galerija',
    galleryAddPhoto: 'Dodaj fotografijo',
    galleryPhoto: 'Fotografija',
    galleryDescription: 'Opis',
    galleryOperations: 'Operacije',
    challenges: 'Izzivi',
    challengesTitle: 'Naslov',
    challengesAdd: 'Dodaj izziv',
    challengesDescription: 'Opis',
    challengesOperations: 'Operacije'
  },
  challenge: {
    challenge: 'Izziv:',
    basicInfo: 'Osnovne informacije',
    title: 'Naslov',
    settings: 'Nastavitve',
    challengeType: 'Tip izziva',
    challengeTypePlaceholder: 'Prosimo, izberite tip izziva',
    question: 'Vprašanje / Navodila',
    challengeImage: 'Fotografija izziva',
    challenged: 'Izziv',
    caseSensitive: 'Občutljivo na velike in male črke',
    correct: 'Pravilno',
    add: 'Dodaj',
    photo: 'Fotografija'
  },
  centre: {
    centre: 'Center:',
    basicInfo: 'Osnovne informacije',
    title: 'Naslov',
    address: 'Naslov',
    phoneNo: 'Telefonska številka',
    email: 'E-pošta',
    otherInfo: 'Druge informacije',
    openHours: 'Odpiralni čas',
    website: 'Spletna stran',
    latitude: 'Zemljepisna širina',
    longitude: 'Zemljepisna dolžina',
    photo: 'Fotografija',
    thumbOnMap: 'Sličica na zemljevidu',
    operations: 'Operacije',
    placeholder: 'Naslov / Opis',
    add: 'Dodaj'
  },
  card: {
    card: 'Kartica:',
    basicInfo: 'Osnovne informacije',
    title: 'Naslov',
    operations: 'Operacije',
    placeholder: 'Naslov / Opis',
    add: 'Dodaj',
    save: 'Shrani',
    name: 'Ime',
    subtitle: 'Podnaslov',
    settings: 'Nastavitve',
    category: 'Kategorija',
    categoryPlaceholder: 'Prosimo, izberite kategorijo',
    subcategory: 'Podkategorija',
    subcategoryPlaceholder: 'Prosimo, izberite podkategorijo',
    description: 'Opis',
    image: 'Slika'
  },
  table: {
    dynamicTips1: 'Fiksna glava, razvrščeno po vrstnem redu glave',
    dynamicTips2: 'Ne fiksna glava, razvrščeno po vrstnem redu klikov',
    dragTips1: 'Privzeti vrstni red',
    dragTips2: 'Vrstni red po povleku',
    title: 'Naslov',
    importance: 'Pomembnost',
    type: 'Tip',
    remark: 'Opomba',
    search: 'Iskanje',
    add: 'Dodaj',
    export: 'Izvoz',
    reviewer: 'Recenzent',
    id: 'ID',
    date: 'Datum',
    author: 'Avtor',
    readings: 'Branja',
    status: 'Status',
    actions: 'Dejanja',
    edit: 'Uredi',
    publish: 'Objavi',
    draft: 'Osnutek',
    delete: 'Izbriši',
    cancel: 'Prekliči',
    confirm: 'Potrdi',
    info: 'Informacije',
    operations: 'Operacije',
    published: 'Objavljeno',
    last_update: 'Zadnja posodobitev'
  }
}