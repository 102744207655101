import Layout from '@/layout'

const profileRouter = {
    path: '/profile',
    component: Layout,
    children: [
      {
        path: 'index',
        component: () => import('@/views/profile/ProfileView'),
        name: 'Profile',
        meta: { title: 'Centres', icon: 'centre', affix: true },
		hidden: true
      },
    ]
  }
export default profileRouter