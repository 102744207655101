<script>
import * as Vue from 'vue'
function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
export default function render(_props, _context) {
  const context = {
    ..._context,
    props: _props,
    data: _context.attr,
    children: _context.slots,
  }
  const { icon, title } = context.props
  const vnodes = []

  if (icon) {

    if (icon.includes('el-icon')) {

      let icn =icon.replace('el-icon-', '')

      icn = icn.charAt(0).toUpperCase() + icn.slice(1);
      //console.log(icn)
      vnodes.push(<component is={icn}></component>)
      //vnodes.push(<i class={[icon, 'sub-el-icon']} />)
      //vnodes.push(<{icn} />)
    } else {
      vnodes.push(<svg-icon icon-class={icon} />)
    }
  }

  if (title) {
    vnodes.push(<span slot="title">{title}</span>)
  }
  return vnodes
}
</script>

<style scoped>
.sub-el-icon {
  color: currentColor;
  width: 1em;
  height: 1em;
}
</style>