export default {
    navbar: {
      dashboard: 'Dashbord',
      github: 'Github',
      logOut: 'Logg ut',
      profile: 'Profil',
      theme: 'Tema',
      size: 'Global størrelse'
    },
    mission: {
      mission: 'Oppdrag:',
      basicInfo: 'Grunnleggende informasjon',
      name: 'Oppdragsnavn',
      location: 'Plassering',
      duration: 'Varighet',
      distance: 'Lengde (avstand)',
      author: 'Forfatter/e',
      centre: 'Senter',
      centrePlaceholder: 'Vennligst velg senter',
      settings: 'Innstillinger',
      missionType: 'Oppdragstype',
      missionTypePlaceholder: 'Vennligst velg oppdragstype',
      language: 'Språk',
      languagePlaceholder: 'Vennligst velg språk',
      theme: 'Tema',
      themePlaceholder: 'Vennligst velg tema',
      area: 'Emne / Fag',
      areaPlaceholder: 'Vennligst velg emne',
      level: 'Nivå',
      levelPlaceholder: 'Vennligst velg vanskelighetsgrad',
      editors: 'Redaktører',
      addEditor: 'Legg til redaktør',
      hiddenMission: 'Skjult oppdrag',
      remoteMission: 'Fjernt oppdrag',
      lockedMission: 'Låst oppdrag',
      missionCode: 'Oppdragskode',
      checkInRange: 'Innsjekkingsområde',
      distanceFromSight: 'Avstand fra synspunkt',
      selectedNoOfPoint: 'Valgt antall poeng',
      missionPresentation: 'Oppdragspresentasjon',
      missionImages: 'Oppdragsbilder',
      titleImage: 'Tittelbilde',
      thumbnail: 'Miniatyrbilde',
      badge: 'Merkelapp',
      badgeImages: 'Merkelappbilder',
      complete: 'Fullfør',
      points: 'Poeng',
      addPoint: 'Legg til poeng',
      id: 'ID',
      title: 'Tittel',
      operation: 'Operasjoner',
      drag: 'Dra',
      pointConnections: 'Punktforbindelser',
      confirm: 'Bekreft',
      cancel: 'Avbryt'
    },
    point: {
      point: 'Punkt:',
      basicInfo: 'Grunnleggende informasjon',
      title: 'Tittel',
      subtitle: 'Undertittel',
      card: 'Kort',
      cardPlaceholder: 'Vennligst velg kort',
      settings: 'Innstillinger',
      latitude: 'Breddegrad',
      longitude: 'Lengdegrad',
      thumbnail: 'Miniatyrbilde',
      description: 'Beskrivelse',
      audio: 'Lyd',
      gallery: 'Galleri',
      galleryAddPhoto: 'Legg til bilde',
      galleryPhoto: 'Foto',
      galleryDescription: 'Beskrivelse',
      galleryOperations: 'Operasjoner',
      challenges: 'Utfordringer',
      challengesTitle: 'Tittel',
      challengesAdd: 'Legg til utfordring',
      challengesDescription: 'Beskrivelse',
      challengesOperations: 'Operasjoner'
    },
    challenge: {
      challenge: 'Utfordring:',
      basicInfo: 'Grunnleggende informasjon',
      title: 'Tittel',
      settings: 'Innstillinger',
      challengeType: 'Utfordringstype',
      challengeTypePlaceholder: 'Vennligst velg utfordringstype',
      question: 'Spørsmål / Instruksjoner',
      challengeImage: 'Utfordringsbilde',
      challenged: 'Utfordret',
      caseSensitive: 'Store og små bokstaver skiller',
      correct: 'Korrekt',
      add: 'Legg til',
      photo: 'Bilde'
    },
    centre: {
      centre: 'Senter:',
      basicInfo: 'Grunnleggende informasjon',
      title: 'Tittel',
      address: 'Adresse',
      phoneNo: 'Telefonnummer',
      email: 'E-post',
      otherInfo: 'Annen informasjon',
      openHours: 'Åpningstider',
      website: 'Nettsted',
      latitude: 'Breddegrad',
      longitude: 'Lengdegrad',
      photo: 'Bilde',
      thumbOnMap: 'Miniatyrbilde på kartet',
      operations: 'Operasjoner',
      placeholder: 'Tittel / Beskrivelse',
      add: 'Legg til'
    },
    card: {
      card: 'Kort:',
      basicInfo: 'Grunnleggende informasjon',
      title: 'Tittel',
      operations: 'Operasjoner',
      placeholder: 'Tittel / Beskrivelse',
      add: 'Legg til',
      save: 'Lagre',
      name: 'Navn',
      subtitle: 'Undertittel',
      settings: 'Innstillinger',
      category: 'Kategori',
      categoryPlaceholder: 'Vennligst velg kategori',
      subcategory: 'Underkategori',
      subcategoryPlaceholder: 'Vennligst velg underkategori',
      description: 'Beskrivelse',
      image: 'Bilde'
    },
    table: {
      dynamicTips1: 'Fast hode, sortert etter hodeordre',
      dynamicTips2: 'Ikke fast hode, sortert etter klikkrekkefølge',
      dragTips1: 'Standard rekkefølge',
      dragTips2: 'Rekkefølge etter draing',
      title: 'Tittel',
      importance: 'Viktighet',
      type: 'Type',
      remark: 'Merknad',
      search: 'Søk',
      add: 'Legg til',
      export: 'Eksporter',
      reviewer: 'Anmelder',
      id: 'ID',
      date: 'Dato',
      author: 'Forfatter',
      readings: 'Lesninger',
      status: 'Status',
      actions: 'Handlinger',
      edit: 'Rediger',
      publish: 'Publiser',
      draft: 'Utkast',
      delete: 'Slett',
      cancel: 'Avbryt',
      confirm: 'Bekreft',
      info: 'Informasjon',
      operations: 'Operasjoner',
      published: 'Publisert',
      last_update: 'Siste oppdatering'
    }
  }
  