import request from '@/utils/request'

export function fetchChallenges(){
	return request({
		url: "/api/missions/challenge/",
		method: "get"
	})
}

export function deleteChallenge(id){
	return request({
		url: `/api/missions/challenge/delete/${id}/`,
		method: "delete"
	})
}
export function fetchDetails(data) {
  return request({
    url: '/api/missions/challenge/'+data+'/',
    method: 'get',
  })
}
export function createChallenge(data) {
	return request({
	  url: '/api/missions/challenge/create/',
	  method: 'post',
	  data
	})
}
export function updateChallenge(id, data) {
	return request({
		url: '/api/missions/challenge/update/'+id+'/',
		method: 'patch',
		data
	})
}