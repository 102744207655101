import Layout from '@/layout'

const cardsRouter =  {
    path: '/card',
    component: Layout,
    meta: { roles: ['admin'], permissions: ['admin'] },
    children: [
      {
        path: '',
        component: () => import('@/views/cards/CardsView.vue'),
        name: 'Cards',
        meta: {
            title: 'Cards',
            icon: 'card',
            affix: false,
            noCache: true,

            //activeMenu: '/article/list'

        }
      },{
        path: ':id',
        component: () => import('@/views/cards/EditCardView.vue'),
        name: 'CardEdit',
        meta: {title: 'Card'},
        hidden: true
      },
	  {
		path: 'add',
		component: () => import('@/views/cards/EditCardView.vue'),
		name: 'CardAdd',
		meta: {
            title: 'Add card'
        },
		hidden: true
	  }
    ]
  }
export default cardsRouter