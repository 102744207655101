import { getDefaultData } from '@/api/main'

export default {
	state() {	
		return {
			defaultData: {
				mission_types:[],
				start_points:[],
				mission_language:[],
				mission_themes:[],
				challenge_types:[],
				cards:{
					cat:[],
					subcat:[]
				},
				area:[],
				difficulty: []
			}
		}
	},
	mutations: {
		setDefault(state, payload) {
			state.defaultData = payload.defaultData
		}
	},
	getters: {
		getDefaultData(state) {
			return state.defaultData
		}
	},
	beforeCreate: function() {
        alert("beforeCreate");
    },
	actions: {
		async fetchDefaultData({ commit }) {
			const response = await getDefaultData()
			//this.$nextTick(()=>{
				commit('setDefault', {
					defaultData: response
				})
			//})

		}
	},
}